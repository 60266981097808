// api/errorReducer.js
import log from '../../utils/log';
const logger = log.getLogger('REDUX_API');

const handler = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE|ERROR)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  switch (requestState) {
    case 'ERROR':
    case 'FAILURE':
      logger.debug('Error', {
        requestName,
        payload
      })
      if (!payload.message && payload.error) {
        payload.message = payload.error instanceof Error ? payload.error.message : payload.error
      }
      break;
    default:
      break;
  }
  return {
    ...state,
    // Store errorMessage
    [requestName]: requestState === 'FAILURE' || requestState === 'ERROR' ? payload.message : '',
  };
};

export default handler
import { combineReducers } from 'redux';
import auth from './auth/reducer';
import api from './api/reducer'
import metrics from './metrics/reducer'
import towers from './towers/reducer'
import theme from './theme/reducer'
import base from './base/reducer'

const reducers = combineReducers({
  api,
  auth,
  metrics,
  towers,
  theme,
  base
});

export default reducers;
export const createAction = (action) => {
  const conf = {
    ACTION: action,
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    ERROR: `${action}_ERROR`
  };
  conf.requestAction = (payload) => ({
    type: conf.REQUEST,
    payload: payload ? payload : {}
  })
  conf.successAction = (payload) => ({
    type: conf.SUCCESS,
    payload: payload ? payload : {}
  })
  conf.errorAction = (error) => ({
    type: conf.ERROR,
    payload: { error }
  })
  return conf
};

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* THEME */
export const THEME_GET = "THEME_GET";
export const THEME_SET = "THEME_SET";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_FIREBASE = "LOGIN_FIREBASE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTH_RESET = createAction('AUTH_RESET')
export const ROLE_REQUEST = createAction('ROLE_REQUEST')

export const VERSION_CHECK = createAction('VERSION_CHECK')

export const FB_TOKEN_CHANGED = createAction('FB_TOKEN_CHANGED')

export const INITIAL_ROUTE = "INITIAL_ROUTE";

export const PUSH_REGISTER = "PUSH_REGISTER";
export const PUSH_REGISTER_STORE = "PUSH_REGISTER_STORE";
export const PUSH_REGISTER_SUCCESS = "PUSH_REGISTER_SUCCESS";
export const PUSH_REGISTER_ERROR = "PUSH_REGISTER_ERROR";

export const PM2_PROCESSES = createAction('PM2_PROCESSES')

export const GENERIC_API_ERROR = "GENERIC_API_ERROR";

/* TOWERS */
export const SITES_SET_SORT = createAction('SITES_SET_SORT');
export const SITES_SET_VIEW = createAction('SITES_SET_VIEW');
export const SITES_SET_SEARCH = createAction('SITES_SET_SEARCH');
export const SITES_SET_FILTERS = createAction('SITES_SET_FILTERS');
export const SITES_CRUD_MODE = createAction('SITES_CRUD_MODE');
export const SITES_CRUD_SUBMIT = createAction('SITES_CRUD_SUBMIT');
export const SITES_DELETE = createAction('SITES_DELETE');

export const CLIENTS_SET_SORT = createAction('CLIENTS_SET_SORT');
export const CLIENTS_SET_VIEW = createAction('CLIENTS_SET_VIEW');
export const CLIENTS_SET_SEARCH = createAction('CLIENTS_SET_SEARCH');
export const CLIENTS_SET_FILTERS = createAction('CLIENTS_SET_FILTERS');
export const CLIENTS_CRUD_MODE = createAction('CLIENTS_CRUD_MODE');
export const CLIENTS_CRUD_SUBMIT = createAction('CLIENTS_CRUD_SUBMIT');
export const CLIENTS_DELETE = createAction('CLIENTS_DELETE');

export const TOWERS_FETCH = createAction('TOWERS_FETCH');
export const TOWERS_SET_SORT = createAction('TOWERS_SET_SORT');
export const TOWERS_SET_VIEW = createAction('TOWERS_SET_VIEW');
export const TOWERS_SET_SEARCH = createAction('TOWERS_SET_SEARCH');
export const TOWERS_SET_FILTERS = createAction('TOWERS_SET_FILTERS');
export const TOWERS_CRUD_MODE = createAction('TOWERS_CRUD_MODE');
export const TOWERS_CRUD_SUBMIT = createAction('TOWERS_CRUD_SUBMIT');
export const TOWERS_DELETE = createAction('TOWERS_DELETE');
export const TOWERS_RELAY_MODE = createAction('TOWERS_RELAY_MODE');
export const TOWERS_RELAY_CRUD_SUBMIT = createAction('TOWERS_RELAY_CRUD_SUBMIT');
export const TOWERS_CAMERA_MODE = createAction('TOWERS_CAMERA_MODE');
export const CAMERAS_FETCH = createAction('CAMERAS_FETCH');
export const CAMERAS_CRUD_SUBMIT = createAction('CAMERAS_CRUD_SUBMIT');
export const TOWERS_CONFIG_CHECK = createAction('TOWERS_CONFIG_CHECK');
export const CAMERAS_CONFIG_CHECK = createAction('CAMERAS_CONFIG_CHECK');
export const TOWERS_W3W_CHECK = createAction('TOWERS_W3W_CHECK');

/* USERS */
export const USERS_FETCH = createAction('USERS_FETCH');
export const USERS_SET_SORT = createAction('USERS_SET_SORT');
export const USERS_SET_VIEW = createAction('USERS_SET_VIEW');
export const USERS_SET_SEARCH = createAction('USERS_SET_SEARCH');
export const USERS_SET_FILTERS = createAction('USERS_SET_FILTERS');
export const USERS_CRUD_MODE = createAction('USERS_CRUD_MODE');
export const USERS_CRUD_SUBMIT = createAction('USERS_CRUD_SUBMIT');
export const USERS_CREATE = createAction('USERS_CREATE');
export const USERS_ACTIVE_TOGGLE = createAction('USERS_ACTIVE_TOGGLE');

/* METRICS */
export const METRICS_FETCH = createAction('METRICS_CONFIGS');
export const METRICS_ACTIONS_FETCH = createAction('METRICS_ACTIONS_FETCH');
export const METRICS_STATS = createAction('METRICS_STATS');
export const METRICS_FETCH_LATEST = createAction('METRICS_FETCH_LATEST');
export const METRICS_CHART_TIMELINE = createAction('METRICS_CHART_TIMELINE');
export const METRIC_HISTORY_FETCH = createAction('METRIC_HISTORY_FETCH');
export const METRICS_HISTORY_SET_FILTERS = createAction('METRICS_HISTORY_SET_FILTERS');

export const RELAY_SET = createAction('RELAY_SET');

export const BUILDS_FETCH = createAction('BUILDS_FETCH');

export const RESTIFY_ENDPOINT_GET = createAction('RESTIFY_ENDPOINT_GET');

/* DEV */
export const REDUX_LOGGER = createAction('REDUX_LOGGER')

/* BASE */
export const SET_CURRENT_ROUTE_KEY = createAction('SET_CURRENT_ROUTE_KEY');

export * from "./api/actions";
export * from "./auth/actions";
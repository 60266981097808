import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

prefix.reg(log);
// log.enableAll();

prefix.apply(log, {
  format(level, name, timestamp) {
    return `[${name}][${level[0]}]`;
  },
});

log.setLevel("trace");

// const hassLogger = log.getLogger("HASS");
// hassLogger.setLevel('INFO');

export default log;
import { all, fork, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import { stardardRequest } from '../../utils';

import {
  TOWERS_FETCH,
  USERS_FETCH,
  USERS_CREATE,
  USERS_ACTIVE_TOGGLE,
  SITES_CRUD_SUBMIT,
  CLIENTS_CRUD_SUBMIT,
  TOWERS_CRUD_SUBMIT,
  TOWERS_RELAY_CRUD_SUBMIT,
  CAMERAS_FETCH,
  CAMERAS_CRUD_SUBMIT,
  CAMERAS_CONFIG_CHECK,
  TOWERS_CONFIG_CHECK,
  TOWERS_W3W_CHECK,
  CLIENTS_DELETE,
  SITES_DELETE,
  TOWERS_DELETE,
  BUILDS_FETCH
} from '../actions';

const apiBase = 'https://api.senamanagement.io/api'

export function* watchTowersFetchRequest() {
  const action = TOWERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/towers/fetch`
    },
    dataPath: 'result',
    dataDefault: [],
    beforeSuccess: (data, state, payload) => {
      data.sites = data.sites.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.clients = data.clients.map(d => {
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      data.towers = data.towers.map(d => {
        d.site.created_at = moment(d.site.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.site.updated_at = moment(d.site.updated_at).format('DD/MM/YYYY HH:mm:ss')
        d.created_at = moment(d.created_at).format('DD/MM/YYYY HH:mm:ss')
        d.updated_at = moment(d.updated_at).format('DD/MM/YYYY HH:mm:ss')
        return d
      })
      return data
    }
  }));
}

export function* watchSitesCrudSubmitRequest() {
  const action = SITES_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/sites`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchSitesCrudDeleteRequest() {
  const action = SITES_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${apiBase}/v1/sites`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchClientsCrudSubmitRequest() {
  const action = CLIENTS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/clients`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchClientsCrudDeleteRequest() {
  const action = CLIENTS_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${apiBase}/v1/clients`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersCrudSubmitRequest() {
  const action = TOWERS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/towers`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersCrudDeleteRequest() {
  const action = TOWERS_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${apiBase}/v1/towers`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowersRelaySubmitRequest() {
  const action = TOWERS_RELAY_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${apiBase}/v1/towers/relay`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchCameraFetchRequest() {
  const action = CAMERAS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${apiBase}/v1/cameras/fetch/${payload._id}`,
        data: payload
      }
    },
    dataPath: 'cameras',
    dataDefault: null
  }));
}

export function* watchCameraSubmitRequest() {
  const action = CAMERAS_CRUD_SUBMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: payload.id ? 'patch' : 'post',
        url: `${apiBase}/v1/cameras`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchTowerConfigCheckRequest() {
  const action = TOWERS_CONFIG_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${apiBase}/v1/towers/check-config`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchCameraConfigCheckRequest() {
  const action = CAMERAS_CONFIG_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${apiBase}/v1/cameras/check-config`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null
  }));
}

export function* watchUsersFetchRequest() {
  const action = USERS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${apiBase}/v1/auth/users/fetch`
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersCreateRequest() {
  const action = USERS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${apiBase}/v1/auth/users/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'users',
    dataDefault: [],
  }));
}

export function* watchUsersActiveToggleRequest() {
  const action = USERS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/auth/users/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'user',
    dataDefault: [],
  }));
}

export function* watchW3wRequest() {
  const action = TOWERS_W3W_CHECK
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/towers/w3w`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchWBuildsFetchRequest() {
  const action = BUILDS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${apiBase}/v1/builds/fetch`,
      }
    },
    dataPath: 'builds',
    dataDefault: [],
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchSitesCrudSubmitRequest),
    fork(watchClientsCrudSubmitRequest),
    fork(watchTowersCrudSubmitRequest),
    fork(watchTowersFetchRequest),
    fork(watchTowersRelaySubmitRequest),
    fork(watchUsersFetchRequest),
    fork(watchUsersCreateRequest),
    fork(watchUsersActiveToggleRequest),
    fork(watchCameraFetchRequest),
    fork(watchCameraSubmitRequest),
    fork(watchCameraConfigCheckRequest),
    fork(watchTowerConfigCheckRequest),
    fork(watchClientsCrudDeleteRequest),
    fork(watchSitesCrudDeleteRequest),
    fork(watchTowersCrudDeleteRequest),
    fork(watchW3wRequest),
    fork(watchWBuildsFetchRequest),
  ]);
}
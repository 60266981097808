import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducers from './reducers';
import sagas from "./sagas";

const persistConfig = {
  key: 'root',
  storage: storage,
  // whitelist: [
  //   'hass',
  // ],
  blacklist: [
    'api',
    'logs',
    'metrics',
    'towers'
  ],
  timeout: null
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export let store = null;

export function configureStore(initialState) {

  const persistedReducer = persistReducer(persistConfig, rootReducers)

  store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  let persistor = persistStore(store)
  return { store, persistor }
}
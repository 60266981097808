import { REHYDRATE } from 'redux-persist';
import {
  METRICS_FETCH,
  METRICS_FETCH_LATEST,
  METRICS_STATS,
  METRICS_ACTIONS_FETCH,
  METRICS_CHART_TIMELINE,
  METRIC_HISTORY_FETCH,
  METRICS_HISTORY_SET_FILTERS
} from '../actions';

const INIT_STATE = {
  metrics: [],
  metrics_latest: [],
  metric_history: [],
  metric_chart_timeline: {},
  audit_actions: [],
  stats: {},
  grid_options: {
    history: {
      view: 'grid',
      sort: 'asc',
      search: null,
      filters: {}
    }
  }
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case METRICS_FETCH.REQUEST: {
      return { ...state, metrics: [] }
    }
    case METRICS_FETCH.SUCCESS: {
      return { ...state, metrics: action.payload }
    }
    case METRICS_FETCH_LATEST.REQUEST: {
      return { ...state, metrics_latest: [] }
    }
    case METRICS_FETCH_LATEST.SUCCESS: {
      return { ...state, metrics_latest: action.payload }
    }
    case METRICS_STATS.SUCCESS: {
      return { ...state, stats: action.payload }
    }
    case METRICS_ACTIONS_FETCH.SUCCESS: {
      return { ...state, audit_actions: action.payload }
    }
    case METRICS_CHART_TIMELINE.SUCCESS: {
      const { metric, data } = action.payload;
      const { metric_chart_timeline } = state;
      return { ...state, metric_chart_timeline: { ...metric_chart_timeline, [metric]: data } }
    }
    case METRIC_HISTORY_FETCH.SUCCESS: {
      return { ...state, metric_history: action.payload }
    }
    case METRICS_HISTORY_SET_FILTERS.REQUEST: {
      return { ...state, grid_options: {
        ...state.grid_options,
        history: {
          ...state.grid_options.history,
          filters: {
            ...action.payload,
            tower: undefined,
            metric: undefined
          }
        }
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler

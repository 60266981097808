import {
  LOGIN_USER,
  LOGIN_FACEBOOK,
  LOGIN_FIREBASE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  INITIAL_ROUTE,
  FB_TOKEN_CHANGED,
  PUSH_REGISTER,
  PUSH_REGISTER_STORE,
  PUSH_REGISTER_SUCCESS,
  PUSH_REGISTER_ERROR
} from '../actions';

export const loginUser = (user, navigation) => ({
  type: LOGIN_USER,
  payload: { user, navigation }
});
export const facebookAuth = (user) => ({
  type: LOGIN_FACEBOOK,
  payload: { user }
});
export const firebaseAuth = (tokenObj) => ({
  type: LOGIN_FIREBASE,
  payload: tokenObj
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});

export const registerUser = (user, navigation) => ({
  type: REGISTER_USER,
  payload: { user, navigation }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: { }
});

export const setInitialRoute = (initialRoute) => ({
  type: INITIAL_ROUTE,
  payload: { initialRoute }
});

export const fbTokenChanged = (token) => ({
  type: FB_TOKEN_CHANGED.REQUEST,
  payload: { token }
})

export const pushRegister = (token) => ({
  type: PUSH_REGISTER,
  payload: { token }
});
export const pushRegisterStore = (token) => ({
  type: PUSH_REGISTER_STORE,
  payload: { token }
});
export const pushRegisterSuccess = () => ({
  type: PUSH_REGISTER_SUCCESS,
  payload: { }
});
export const pushRegisterError = (err) => ({
  type: PUSH_REGISTER_ERROR,
  payload: { error: err }
});
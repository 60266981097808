import {
  SET_CURRENT_ROUTE_KEY
} from '../actions';

import { REHYDRATE } from 'redux-persist';

const INIT_STATE = {
  currentRouteKey: '',
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_ROUTE_KEY:
      return { ...state, currentRouteKey: action.payload };
    case REHYDRATE: {
      return { ...state, ...action.payload }
    }
    default: return { ...state };
  }
}

export default handler
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import metricsSaga from './metrics/saga'
import towersSaga from './towers/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    metricsSaga(),
    towersSaga()
  ]);
}

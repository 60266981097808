import { initializeApp } from "firebase/app";
import {
  getAuth,
} from 'firebase/auth'
import axios from 'axios'
axios.defaults.headers.common['x-auth-strategy'] = 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfd0XQVOPf413XSTYIGTOLZnV4qX7Y0QI",
  authDomain: "cctv-tower.firebaseapp.com",
  projectId: "cctv-tower",
  storageBucket: "cctv-tower.appspot.com",
  messagingSenderId: "346009218903",
  appId: "1:346009218903:web:4ef79cab95c431c5369812",
  measurementId: "G-3513J9FN98"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = () => {
  return getAuth(app)
}

export default auth